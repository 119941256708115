import { TextInput, Select } from '@mantine/core';

import styles from '../../index.module.css';
const {section, sectionTitle, inputRow, input, label, divider} = styles;

const inputStyles = {input: input, label: label};

const Service = ({form}) => {
  return (
    <>
        <section className={section}>
            <h3 className={sectionTitle}>Servicing Provider Demographics</h3>
            <div className={inputRow}>
                <TextInput
                    label="Name"
                    classNames={inputStyles}
                />
                <TextInput
                    label="NPI#"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Phone"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Fax"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Address"
                    classNames={inputStyles}
                />
                <TextInput
                    label="City"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="State-ZIP"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Specialty"
                    classNames={inputStyles}
                />
            </div>
            <div className={divider}></div>
        </section>
    </>
  )
}

export default Service;