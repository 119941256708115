import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { useDispatch } from "react-redux";
import { setUser, setOrg, setRole, setMedicalGroup } from "./authSlice";
import { useEffect, useState } from "react";

export const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const user = sessionStorage.getItem("afya_user");
    const userOrg = sessionStorage.getItem("userOrg") === 'null' ? null : sessionStorage.getItem("userOrg");
    const userRole = sessionStorage.getItem("userRole") === 'null' ? null : sessionStorage.getItem("userRole");
    const userMedicalGroup = sessionStorage.getItem("userMedicalGroup") === 'null' ? null : sessionStorage.getItem("userMedicalGroup");
    const userData = JSON.parse(user);
    if (userData) {
      dispatch(setUser(userData));
    }
    dispatch(setOrg(userOrg));
    dispatch(setRole(userRole));
    dispatch(setMedicalGroup(userMedicalGroup));
    setIsLoading(false);
  }, []);

  const auth_token = useSelector((state) => state.auth).token;
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <NavigateConditional children={children} />
  );
};

const NavigateConditional = ({ children }) => {
  const auth_token = useSelector((state) => state.auth).token;
  return auth_token ? children : <Navigate to="/login" replace />;
};
