import {
  createStyles,
  Button,
  Group,
  Modal,
  TextInput,
  Container,
  Title,
  Select,
  MultiSelect,
  Textarea,
} from "@mantine/core";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  incrementFormPage,
  setDownloadURL,
  setCurrentAfyaId,
  setProviderInfo,
} from "./referralSlice";
import PCPSelectItem from "./PCPSelectItem";
import { createNotification } from "../Notifications/index";
import { createReferral, findICDCodes, findPCPs, updateReferral } from "../../API";
import { formatName } from "../../utils";
const schema = Yup.object().shape({});

const useStyles = createStyles((theme, _params, getRef) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#E5E5E5",
    justifyContent: "center",
  },
  controlRow: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  col1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "300px",
    marginRight: "50px",
    marginLeft: "25px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "300px",
  },
  firstPage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
    width: "500px",
    margin: "0 auto",
    padding: "0px",
  },
  input: { width: "250px" },
  textArea: { width: "250px", height: "400px", marginTop: "14px" },
  button: {
    backgroundColor: "#4A7CBD",

    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#4A7CBD", 0.05),
    },
  },
  button2: {
    backgroundColor: "#E17A3D",

    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#E17A3D", 0.05),
    },
  },
}));

export const ProviderForm = () => {
  const [ICDCodes, setICDCodes] = useState([]);
  const [pcpOptions, setPCPOptions] = useState([]);
  const [PCPName, setPCPName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { activeReferral } = useSelector((state) => state.referral);
  const patientInfo = useSelector((state) => state.referral).patientInfo;
  const auth_token = useSelector((state) => state.auth).token;
  const [pcp, setPcp] = useState(null);
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {},
  });

  const isReferralActive = () => {
    return Object.keys(activeReferral).length > 0 ? true : false;
  };

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        if (isReferralActive()) {
          const providerInfo = {
            servicing_provider: values.servicing_provider,
            order_id: values.order_id,
            diagnosis: activeReferral.diagnosis,
            symptoms_reason_question: activeReferral.symptoms,
            pcp: activeReferral.pcp_name,
            pcp_npi: activeReferral.pcp_npi,
            pcp_id: activeReferral.pcp_id,
          };
          try {
            setLoading(true);
            const res = await updateReferral(
              { ...providerInfo, ...patientInfo, afya_id: activeReferral.afya_id },
              auth_token
            );
            setLoading(false);
            dispatch(setDownloadURL(res.data.download_url));
            dispatch(setCurrentAfyaId(res.data.afya_id));
            dispatch(incrementFormPage());
          } catch (error) {
            createNotification(
              "Something went wrong",
              "The referral could not be updated"
            );
          }
          return;
        }
        values.diagnosis = [values.diagnosis];
        dispatch(setProviderInfo(values));
        try {
          setLoading(true);
          const res = await createReferral(
            { ...values, ...patientInfo },
            auth_token
          );
          setLoading(false);
          dispatch(setDownloadURL(res.data.download_url));
          dispatch(setCurrentAfyaId(res.data.afya_id));
          dispatch(incrementFormPage());
        } catch (error) {
          createNotification(
            "Something went wrong",
            "The referral could not be created"
          );
        }
      })}
      style={{
        height: "400px",
        paddingTop: "20px",
        paddingBottom: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Title order={3} align="center" style={{ color: "#4D7ABE" }}>
        New Specialty Referral
      </Title>

      <div className={classes.firstPage}>
        <div className={classes.col1}>
          { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Diagnosis"
            disabled={true}
            value={activeReferral.diagnosis[0]}
            />
            ) : (
              <Select
              className={classes.input}
              label="Diagnosis"
              required
              searchable
              data={ICDCodes}
              onSearchChange={async (input) => {
                if (!input) return;
                const searchText = input.split(' ').length > 1 ? input.split(' ')[0] : input;
                const codes = await findICDCodes(searchText);
                setICDCodes(codes);
              }}
              {...form.getInputProps("diagnosis")}
            />
          )}
          { isReferralActive() ? (
            <Textarea
            className={classes.textArea}
            minRows={8}
            maxRows={8}
            label="Symptoms to be addressed"
            disabled={true}
            value={activeReferral.symptoms}
          />
          ) : (
            <Textarea
            className={classes.textArea}
            minRows={8}
            maxRows={8}
            label="Symptoms to be addressed"
            required
            {...form.getInputProps("symptom_reason_question")}
          />
          )}
        </div>
        <div className={classes.col2}>
          <TextInput
            className={classes.input}
            label="Specialist Physician/Clinic/Group"
            required
            {...form.getInputProps("servicing_provider")}
          />
          { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Referring PCP Physician/Clinic/Group"
            disabled={true}
            value={activeReferral.pcp_name}
          />
          ) : (
            <Select
              className={classes.input}
              label="Referring PCP Physician/Clinic/Group"
              required
              clearable
              searchable
              data={pcpOptions}
              onSearchChange={async (input) => {
                let pcpResults = [];
                try {
                  pcpResults = await findPCPs(input, auth_token);
                } catch (error) {
                  console.error("Error in getting pcps", error);
                }
                setPCPOptions(pcpResults);
              }}
              onChange={(value) => {
                if (value) {
                  const { first_name, last_name, user_id, npi, agora_id } =
                    JSON.parse(value);
                  const full_name = formatName(first_name, last_name);
                  form.setFieldValue("pcp", full_name);
                  form.setFieldValue("pcp_id", user_id);
                  form.setFieldValue("pcp_npi", npi);
                  form.setFieldValue("pcp_agora_id", agora_id);
                }
                setPcp(value);
              }}
              value={pcp}
            />
          )}
          { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Referring PCP/Clinic/Group NPI"
            disabled={true}
            value={activeReferral.pcp_npi}
          />
          ) : (
            <TextInput
              className={classes.input}
              label="Referring PCP/Clinic/Group NPI"
              required
              value={form.values.pcp_npi}
              onChange={(event) => {
                form.setFieldValue("pcp_npi", event.target.value);
              }}
            />
          )}
          <TextInput
            className={classes.input}
            label="Order Id"
            required
            {...form.getInputProps("order_id")}
          />
        </div>

        <Container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Container>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "50px",
        }}
      >
        <Button className={classes.button2} type="submit" loading={isLoading}>
          { isReferralActive() ? 'Update Referral' : 'Create Referral' }
        </Button>
      </div>
    </form>
  );
};
