import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setSpecFilter } from "./referralSlice";
import { getAllSpecs } from "../../API";
import usefilterStyles from "./filterStyles";

const SpecialtySelect = () => {
  const specFilter = useSelector((state) => state.referral).specFilter;
  const dispatch = useDispatch();
  const [specOptions, setSpecOptions] = useState([]);
  const { classes } = usefilterStyles();

  useEffect(() => {
    const getSpecOptions = async () => {
      let specs = await getAllSpecs();
      specs.unshift("All");
      specs = specs.filter(spec => spec !== null);
      setSpecOptions(specs);
    };
    getSpecOptions();
  }, []);

  return (
    <Select
      className={classes.filterStyles}
      label="Specialty"
      data={specOptions}
      searchable
      clearable
      value={specFilter}
      onChange={(value) => {
        dispatch(setSpecFilter(value));
      }}
    />
  );
};

export default SpecialtySelect;
