import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  specFilter: "All",
  statusFilter: "All",
  clinicFilter: "All",
  PCPFilter: "All",
  patientFilter: "All",
  PCPFilter: "All",
  patientInfo: {},
  providerInfo: {},
  downloadURL: null,
  currentAfyaId: null,
  formPage: 0,
  fromTable: false,
  activeReferral: {}
};

export const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    setPatientInfo: (state, action) => {
      state.patientInfo = action.payload;
    },
    setProviderInfo: (state, action) => {
      state.providerInfo = action.payload;
    },
    incrementFormPage: (state, action) => {
      state.formPage += 1;
    },
    decrementFormPage: (state, action) => {
      state.formPage -= 1;
    },
    resetFormPage: (state, action) => {
      state.formPage = 0;
    },
    setFormPage: (state, action) => {
      state.formPage = action.payload;
    },
    setFromTable(state, action) {
      state.fromTable = action.payload;
    },
    setDownloadURL: (state, action) => {
      state.downloadURL = action.payload;
    },
    setCurrentAfyaId: (state, action) => {
      state.currentAfyaId = action.payload;
    },
    resetFormData: (state, action) => {
      state.patientInfo = initialState.patientInfo;
      state.providerInfo = initialState.providerInfo;
      state.downloadURL = initialState.downloadURL;
      state.currentAfyaId = initialState.currentAfyaId;
      state.formPage = initialState.formPage;
      state.fromTable = initialState.fromTable;
      state.activeReferral = {};
    },
    setSpecFilter: (state, action) => {
      state.specFilter = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
    setClinicFilter: (state, action) => {
      state.clinicFilter = action.payload;
    },
    setPCPFilter: (state, action) => {
      state.PCPFilter = action.payload;
    },
    setPatientFilter: (state, action) => {
      state.patientFilter = action.payload;
    },
    setActiveReferral: (state, { payload }) => {
      state.activeReferral = payload;
    }
  },
});

export const {
  setPatientInfo,
  setProviderInfo,
  incrementFormPage,
  decrementFormPage,
  setFormPage,
  setFromTable,
  resetFormPage,
  resetFormData,
  setDownloadURL,
  setCurrentAfyaId,
  setSpecFilter,
  setStatusFilter,
  setClinicFilter,
  setPCPFilter,
  setPatientFilter,
  setActiveReferral
} = referralSlice.actions;

export default referralSlice.reducer;
