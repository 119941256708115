import {
  createStyles,
  Button,
  Group,
  Modal,
  TextInput,
  Container,
  Title,
  Select,
  Textarea,
} from "@mantine/core";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import {
  incrementFormPage,
  decrementFormPage,
  resetFormPage,
  setPatientInfo,
} from "./referralSlice";
import { TableSort } from "./TableSort";
import "dayjs/locale/en";
import { DatePicker } from "@mantine/dates";
import {
  planOptions,
  selectOptions,
  planTypeOptions,
  specialties,
} from "./options";
import { addPatient, findPatient, getPatientId, findSpec, isDoctorAvailable } from "../../API";
import { createNotification } from "../Notifications";
import { useDebouncedValue } from "@mantine/hooks";
import { OTHER } from "../../constants";
const schema = Yup.object().shape({});

const useStyles = createStyles((theme, _params, getRef) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#E5E5E5",
    justifyContent: "center",
  },
  controlRow: {
    // backgroundColor: "red",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  col1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "300px",
    marginRight: "50px",
    marginLeft: "25px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "300px",
  },
  firstPage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
    width: "500px",
    margin: "0 auto",
    padding: "0px",
  },
  input: { width: "250px" },
  textArea: { width: "250px", height: "400px" },
  button: {
    backgroundColor: "#4A7CBD",

    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#4A7CBD", 0.05),
    },
  },
  button2: {
    backgroundColor: "#E17A3D",

    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#E17A3D", 0.05),
    },
  },
}));

const getDate = (value) => {
  const dateSplit = value.toLocaleDateString("en-GB").split('/');
  const date = dateSplit[0];
  const month = dateSplit[1];
  const year = dateSplit[2];
  return `${month}-${date}-${year}`;
};

export const PatientForm = () => {
  const {token:auth_token, role, user:{uid:user_id}, org_id} = useSelector((state) => state.auth);
  const { activeReferral } = useSelector((state) => state.referral);
  const [names, setNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patientsData, setPatientsData] = useState({});
  const [didCreate, setDidCreate] = useState(false);
  const [specOptions, setSpecOptions] = useState([]);

  const specialtyOptions = specialties.map((specialty) => {
    return { value: specialty, label: specialty };
  });
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {},
  });

  const [ debouncedDob ] = useDebouncedValue(form.values.dob, 500);

  useEffect(() => {
    const dob = debouncedDob
                ? typeof debouncedDob === 'object' ? getDate(debouncedDob) : ""
                : "";
    const getPatients = async () => {
      const { nameOptions, patientsData } = await findPatient(
        dob,
        "",
        auth_token
      );
      setNames(nameOptions);
      setPatientsData(patientsData);
    };

    if (dob && role !== OTHER) {
      getPatients();
    }
  }, [debouncedDob]);

  const isReferralActive = () => {
    return Object.keys(activeReferral).length > 0 ? true : false;
  };

  useEffect(() => {
    if (isReferralActive()) {
      form.setFieldValue("dob", new Date(activeReferral.patient_dob));
      form.setFieldValue("sex", activeReferral.sex);
      form.setFieldValue("member_id", activeReferral.patient_member_id);
    }
  }, [activeReferral]);

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        if (isReferralActive()) {
          const { patient_dob, sex, patient_member_id, patient_id, specialty, patient_first, patient_last } = activeReferral;
          const patientInfo = {
            dob: patient_dob,
            sex,
            member_id: patient_member_id,
            patient_id: patient_id,
            // spec:, //not requried
            patient_plan: values.patient_plan,
            hpsj_select: values.hpsj_select,
            plan_type:values.plan_type,
            specialty: specialty,
            // live_status:, //not requried
            // master_spec_id:, //not requried
            patient_first,
            patient_last,
            // patient_agora_id:, //not requried
          };
          dispatch(setPatientInfo(patientInfo));
          dispatch(incrementFormPage());
          return;
        }
        const { name, live_status, master_spec_id } = JSON.parse(values.spec);
        if (live_status) {
          const doctorData = await isDoctorAvailable({
            org_id: org_id,
            owner_id_1: user_id,
            specialty: master_spec_id
          });
          if (!doctorData.isdoctor) {
            createNotification("Message", "No doctors are available to respond at this moment. Please call (209) 800-4064 for support and next steps.","ERROR",10000);
            return;
          }
        }
        values.specialty = name;
        values.live_status = live_status;
        values.master_spec_id = master_spec_id;
        const uid = values.patient_id;
        if (uid in patientsData) {
          values.patient_first = patientsData[uid].first_name;
          values.patient_last = patientsData[uid].last_name;
          values.patient_agora_id = patientsData[uid].agora_id;
        } else if (didCreate) {
          const { patient_id, sex, dob, member_id } = values;
          const names = patient_id.split(" ");
          if (names.length < 2) {
            createNotification(
              "Patient Name Invalid",
              "Please use first and last name"
            );
            return;
          }
          const first_name = names[0];
          const last_name = names.at(-1);
          values.patient_first = first_name;
          values.patient_last = last_name;
          setIsLoading(true);
          try {
            const res = await addPatient(
              first_name,
              last_name,
              getDate(dob),
              sex,
              member_id,
              auth_token
            );
            values.patient_id = res.user_id;
            values.patient_agora_id = res.agora_id;
            setIsLoading(false);
            createNotification("Success!", "Patient was created", "SUCCESS");
          } catch (error) {
            setIsLoading(false);
            createNotification(
              "Something went wrong",
              "Patient could not be created"
            );
            return;
          }
        }
        values.dob = values.dob.toString();
        dispatch(setPatientInfo(values));
        dispatch(incrementFormPage());
      })}
      style={{
        height: "400px",
        paddingTop: "20px",
        paddingBottom: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Title
        order={3}
        align="center"
        style={{ color: "#4D7ABE" }}
        data-autofocus
      >
        New Specialty Referral
      </Title>

      <div className={classes.firstPage}>
        <div className={classes.col1}>
          <DatePicker
            initiallyOpened={false}
            className={classes.input}
            label="Patient Date of Birth"
            locale="en"
            allowFreeInput
            required
            disabled={isReferralActive()}
            {...form.getInputProps("dob")}
          />
          { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Patient Name"
            disabled={true}
            value={activeReferral.patient_name}
          />
          ) : (
            <Select
            className={classes.input}
            label="Patient Name"
            searchable={true}
            disabled={!form.values.dob}
            data={names}
            onSearchChange={async (input) => {
              if (role === OTHER) return;
            }}
            required
            creatable
            onChange={(value) => {
              form.setFieldValue("sex", '');
              form.setFieldValue("member_id", '');
              form.setFieldValue("patient_id", value);
              if (!(value in patientsData)) return;
              const patient = patientsData[value];
              const sex =
                "sex" in patient ? patientsData[value].sex.toString() : null;
              const member_id =
                "member_id" in patient ? patientsData[value].member_id : null;
              form.setFieldValue("sex", sex);
              form.setFieldValue("member_id", member_id);
            }}
            value={form.values.patient_id}
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              setNames((names) => [...names, query]);
              form.setFieldValue("patient_id", query);
              setDidCreate(true);
            }}
            shouldCreate={(query, item) => true}
          />
          )}
          <Select
            className={classes.input}
            label="Patient Sex"
            disabled={!form.values.patient_id}
            value={form.values.sex}
            required
            data={["Female", "Male"]}
            onChange={(value) => {
              form.setFieldValue("sex", value);
            }}
          />
          <TextInput
            className={classes.input}
            label="Patient Health Plan Id"
            disabled={!form.values.patient_id}
            value={form.values.member_id}
            onChange={(event) => {
              form.setFieldValue("member_id", event.target.value);
            }}
            required
          />
        </div>
        <div className={classes.col2}>
          { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Specialty"
            disabled={true}
            value={activeReferral.specialty}
          />) : (
            <Select
            className={classes.input}
            label="Specialty"
            data={specOptions}
            searchable
            onSearchChange={async (input) => {
              if (!input || role === OTHER) return;
              try {
                const specs = await findSpec(input, auth_token);
                setSpecOptions(specs);
              } catch (error) {
                createNotification(
                  "Something went wrong",
                  "Specialty service could not be reached"
                );
              }
            }}
            required
            {...form.getInputProps("spec")}
          />
          )}
          <Select
            className={classes.input}
            label="Patient Plan"
            data={planOptions}
            required
            searchable
            {...form.getInputProps("patient_plan")}
          />
          <Select
            className={classes.input}
            label="County of San Joaquin Select"
            data={selectOptions}
            required
            searchable
            {...form.getInputProps("hpsj_select")}
          />
          <Select
            className={classes.input}
            label="County of San Joaquin"
            data={planTypeOptions}
            required
            searchable
            {...form.getInputProps("plan_type")}
          />
        </div>

        <Container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Container>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "50px",
        }}
      >
        <Button className={classes.button2} loading={isLoading} type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};
