import React, { useState, useEffect, useRef } from "react";

export const useInterval = (callback, delay, enable) => {
  const savedCallback = useRef();
  useEffect(() => {
    if (enable) {
      savedCallback.current = callback;
    }
  }, [callback]);

  useEffect(() => {
    if (enable) {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }
  }, [delay]);
};

export const useObservable = (variable) => {
  useEffect(() => {
    console.log({ variable });
  }, [variable]);
};
