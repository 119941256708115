import HPSJLogo from "./assets/HPSJ butterfly.png";
import AfyaLogo from './assets/afya-logo.png';
import IEHPLogo from './assets/IEHP logo.png';
import IEHPCareLogo from './assets/IEHPCare-logo.png';

export const sites = [
  {
    url: 'stage-vrp-frontend-dot-afya-staging.appspot.com',
    client: 'hpsj',
    logo: HPSJLogo,
    appTitle: 'eCARE Access'
  },
  {
    url: 'uat-vrp-frontend-dot-afya-uat.uc.r.appspot.com',
    client: 'hpsj',
    logo: HPSJLogo,
    appTitle: 'eCARE Access'
  },
  {
    url: 'portal.ecaresj.com',
    client: 'hpsj',
    logo: HPSJLogo,
    appTitle: 'eCARE Access'
  },
  {
    url: 'stage-vrp-afya-frontend-dot-afya-staging.appspot.com',
    client: 'afya',
    logo: AfyaLogo,
    appTitle: 'Afya'
  },
  {
    url: 'uat-vrp-afya-frontend-dot-afya-uat.uc.r.appspot.com',
    client: 'afya',
    logo: AfyaLogo,
    appTitle: 'Afya'
  },
  {
    url: 'portal.afya.chat',
    client: 'afya',
    logo: AfyaLogo,
    appTitle: 'Afya'
  },
  {
    url: 'stage-vrp-iehp-frontend-dot-afya-staging.appspot.com',
    client: 'iehp',
    logo: IEHPLogo,
    appTitle: 'IEHP',
    for: 'QIA Program'
  },
  {
    url: 'uat-vrp-iehp-frontend-dot-afya-uat.uc.r.appspot.com',
    client: 'iehp',
    logo: IEHPLogo,
    appTitle: 'IEHP',
    for: 'QIA Program'
  },
  {
    url: 'iehp.hubmd.org',
    client: 'iehp',
    logo: IEHPLogo,
    appTitle: 'IEHP',
    for: 'QIA Program'
  },
  {
    url: 'stage-vrp-iehpcare-frontend-dot-afya-staging.appspot.com',
    client: 'iehpcare',
    logo: IEHPCareLogo,
    appTitle: 'IEHP CareNow',
    for: ''
  },
  {
    url: 'uat-vrp-iehpcare-frontend-dot-afya-uat.uc.r.appspot.com',
    client: 'iehpcare',
    logo: IEHPCareLogo,
    appTitle: 'IEHP CareNow',
    for: ''
  },
  {
    url: 'portal.iehpcare.org',
    client: 'iehpcare',
    logo: IEHPCareLogo,
    appTitle: 'IEHP CareNow',
    for: ''
  },
  {
    url: 'localhost',
    client: 'iehpcare',
    logo: IEHPCareLogo,
    appTitle: 'IEHP CareNow',
    for: ''
  },
  // {
  //   url: 'localhost',
  //   client: 'afya',
  //   logo: AfyaLogo,
  //   appTitle: 'Afya'
  // },
];
