import {
  AppShell,
  Header,
  Text,
  Button,
  createStyles,
  Group,
  Paper,
  Avatar,
  Popover,
} from "@mantine/core";
import { Dashboard } from "../Dashboard";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HPSJLogo from "../../assets/HPSJ butterfly.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { formatName, getClient } from "../../utils";
import { signOut } from "../../API";
import { clearUser } from "../RequireAuth/authSlice";
const useStyles = createStyles((theme, _params, getRef) => ({
  logo: { width: "75px", height: "75px", objectFit: 'contain' },
  topText: {
    color: "#4A7CBD",
    fontSize: "22px",
    fontWeight: "bold",
    fontFamily: "arial",
  },
  bottomText: {
    color: "#E17A3D",
    fontSize: "15px",
    fontWeight: "bold",
    fontFamily: "arial",
  },
  button: {
    backgroundColor: "#4A7CBD",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#4A7CBD", 0.05),
    },
  },
}));

export const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuStatus] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { first_name, last_name, email, uid } = auth.user;
  const initials = first_name ? first_name[0] + last_name?.at(0) : null;
  let displayEmail = email ? email : "";
  if (displayEmail.length > 24)
    displayEmail = displayEmail.slice(0, 21) + "...";
  const { classes } = useStyles();
  const clientSite = getClient();
  return (
    <AppShell
      styles={(theme) => ({
        backgroundColor: "#F2F3F3",
        height: "100vh",
        width: "100vw",
        main: {
          backgroundColor: "#F2F3F3",
          display: "flex",
          flex: 1,
          padding: "0px",
        },
      })}
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "0px 1px 4px #9E9E9E",
            height: "80px",
            backgroundColor: "white",
            position: "relative",
            zIndex: 10,
          }}
        >
          <Group style={{ marginLeft: "1em" }}>
            <img src={clientSite.logo} className={classes.logo} />
            <Group direction="column" spacing={0}>
              <Text className={classes.topText}>{clientSite.appTitle}</Text>
              <Text className={classes.bottomText}>
              {clientSite.for || "Virtual Referral Portal"}
              </Text>
            </Group>
          </Group>
          {first_name && (
            <Popover
              opened={menuIsOpen}
              onClose={() => setMenuStatus(false)}
              sx={{ marginRight: "2em" }}
              placement="end"
              target={
                <Avatar
                  radius="xl"
                  size="md"
                  color="cyan"
                  style={{ marginRight: "1em" }}
                  onClick={() => setMenuStatus((status) => !status)}
                >
                  {initials}
                </Avatar>
              }
              width={240}
              position="bottom"
            >
              <Group
                direction="column"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    radius="xl"
                    size="md"
                    color="cyan"
                    style={{ marginRight: "1em" }}
                    onClick={() => setMenuStatus((status) => !status)}
                  >
                    {initials}
                  </Avatar>
                  <div
                    sx={{
                      backgroundColor: "red",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{}}>{formatName(first_name, last_name)}</Text>
                    <Text size="xs" style={{ color: "grey" }}>
                      {displayEmail}
                    </Text>
                  </div>
                </div>
                <Button
                  variant="subtle"
                  onClick={async () => {
                    dispatch(clearUser());
                    setMenuStatus(false);
                    navigate("/login");
                  }}
                >
                  Log Out
                </Button>
              </Group>
            </Popover>
          )}
        </div>
      }
    >
      <Outlet />
    </AppShell>
  );
};
