import { showNotification } from "@mantine/notifications";
import { AlertCircle, CircleCheck } from "tabler-icons-react";
export const createNotification = (
  title = "Something went wrong",
  message = "A problem occured",
  type = "ERROR",
  autoClose
) => {
  showNotification({
    color: type === "ERROR" ? "red" : "green",
    icon: type === "ERROR" ? <AlertCircle /> : <CircleCheck />,
    title,
    message,
    autoClose
  });
};
