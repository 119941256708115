import { useRef } from 'react';
import { createStyles, Textarea } from '@mantine/core';
import deleteIcon from '../../../../assets/x.png';
import prettyBytes from 'pretty-bytes';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader'

import styles from '../../index.module.css';

const {section, sectionTitle, textarea, label, divider} = styles;
const inputStyles = {input: textarea, label: label};

const useStyles = createStyles((theme) => ({
  uploadDescription: {
    color: '#A0A0A0',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px'
  },
  dropzone: {
    minHeight: '200px',
    position: 'relative',
    backgroundColor: '#F9F8FC',
    border: '1px solid #EAEAEA',
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    fontSize: '20px',
    fontWeight: '600',
    color: '#2484ff',
    cursor: 'pointer',
    backgroundColor: '#F9F8FC',
    border: '1px solid #EAEAEA',
  },
  inputLabelWithFiles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    padding: '0 14px',
    minHeight: '32px',
    backgroundColor: '#E6E6E6',
    color: '#2484FF',
    border: 'none',
    fontFamily: '\'Helvetica\', sans-serif',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '600',
    marginTop: '20px',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    cursor: 'pointer',
    marginTop: '9rem'
  },
  file: {
    backgroundColor: '#FFF',
    border: '1px solid #EAEAEA',
    padding: '0.75rem',
    display: 'flex',
    alignItems: 'center',

    span: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '16px',
      margin: '0 7rem'
    },

    button: {
      backgroundImage: `url(${deleteIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#faf7fc00',
      width: '2rem',
      height: '2rem',
      border: 'transparent',
      cursor: 'pointer'
    }
  }
}));


const CommentsAndFileUpload = () => {
  const { classes } = useStyles();
  const ref = useRef();

  const Preview = ({fileWithMeta}) => {
    const { file, meta, remove } = fileWithMeta;
    return (
      <div className={classes.file}>
        <span style={{flexGrow: '1', margin: '0'}}>{file.name}</span>
        <span>{prettyBytes(file.size)}</span>
        <button type='button' onClick={() => { remove() }}></button>
    </div>
    )
  }

  return (
    <>
        <section className={section} style={{minHeight: '30rem'}}>
            <h3 className={sectionTitle}>Special Instructions/Comments</h3>
            <Textarea
                label="Comment here"
                classNames={inputStyles}
                minRows={5}
            />
            <div className={divider}></div>
            <h3 className={sectionTitle}>Attach Supporting Documents</h3>
            <div className={classes.uploadDescription}>
              <p>Up to 8 PDF or Word files, 10 MB per file maximum size</p>
              <p>Note: Dragging and droping files into the browser window may navigate away from page</p>
            </div>
            <Dropzone
              ref={ref}
              PreviewComponent={Preview}
              autoUpload={false}
              accept=".pdf, .doc, .docx"
              maxFiles={8}
              classNames={{dropzone: classes.dropzone, inputLabel: classes.inputLabel, inputLabelWithFiles: classes.inputLabelWithFiles}}
            />
            <div className={divider}></div>
        </section>
    </>
  )
}

export default CommentsAndFileUpload;