import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPatientFilter } from "./referralSlice";
import { findReferralUsers } from "../../API";
import { useState } from "react";
import usefilterStyles from "./filterStyles";

const PatientSelect = () => {
  const patientFilter = useSelector((state) => state.referral).patientFilter;
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const { classes } = usefilterStyles();
  return (
    <Select
      className={classes.filterStyles}
      label="Patient"
      data={results}
      searchable
      clearable
      value={patientFilter}
      onSearchChange={async (input) => {
        try {
          const data = await findReferralUsers(input, "patient");
          data.unshift({ value: "All", label: "All" });
          setResults(data);
        } catch (e) {
          console.error(e);
        }
      }}
      onChange={(value) => {
        dispatch(setPatientFilter(value));
      }}
    />
  );
};

export default PatientSelect;
