import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setClinicFilter } from "./referralSlice";
import { getAllClinics } from "../../API";
import usefilterStyles from "./filterStyles";

const ClinicSelect = () => {
  const clinicFilter = useSelector((state) => state.referral).clinicFilter;
  const dispatch = useDispatch();
  const [clinicOptions, setClinicOptions] = useState([]);
  const { classes } = usefilterStyles();

  useEffect(() => {
    const getClinicOptions = async () => {
      const clinics = await getAllClinics();
      clinics.unshift({ value: "All", label: "All" });
      setClinicOptions(clinics);
    };
    getClinicOptions();
  }, []);

  return (
    <Select
      className={classes.filterStyles}
      label="Clinic"
      data={clinicOptions}
      searchable
      clearable
      value={clinicFilter}
      onChange={(value) => {
        dispatch(setClinicFilter(value));
      }}
    />
  );
};

export default ClinicSelect;
