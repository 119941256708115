import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { RequireAuth } from "./components/RequireAuth";
import { Login } from "./components/Login";
import { Dashboard } from "./components/Dashboard";
import { Layout } from "./components/Layout";
import { NotificationsProvider } from "@mantine/notifications";
import LogRocket from "logrocket";
import RequestForm from "./components/RequestForm";

LogRocket.init("rma2bl/virtual-referral-portal");
console.log("Build v0.6");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        theme={{
          style: { backgroundColor: "#F2F3F3" },
          fontFamily: "Roboto, Open Sans, Arial",
          backgroundColor: "blue",
          colors: {
            "pastel-blue": ["#4A7CBD"],
            "bright-pink": [],
          },
        }}
      >
        <NotificationsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<Login />}></Route>
                <Route
                    path="/request-form"
                    element={
                      <RequireAuth>
                        <RequestForm />
                      </RequireAuth>
                    }
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
