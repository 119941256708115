import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setStatusFilter } from "./referralSlice";
import useFilterStyles from "./filterStyles";

const StatusSelect = () => {
  const statusFilter = useSelector((state) => state.referral).statusFilter;
  const dispatch = useDispatch();
  const { classes } = useFilterStyles();

  const options = [
    { value: "All", label: "All" },
    { value: "Fax Delivered", label: "Fax Delivered" },
    { value: "Fax Failed", label: "Fax Failed" },
    { value: "Referral Processing", label: "Referral Processing" },
    { value: "eCARE Completed", label: "eCARE Completed" },
    { value: "eCARE In Progress", label: "eCARE In Progress" },
    { value: "Needs Completion", label: "Needs Completion" },
    { value: "Enrolled", label: "Enrolled" },
    { value: "In Progress", label: "In Progress" },
  ];

  return (
    <Select
      className={classes.filterStyles}
      label="Current Status"
      data={options}
      searchable
      clearable
      value={statusFilter}
      onChange={(value) => {
        dispatch(setStatusFilter(value));
      }}
    />
  );
};

export default StatusSelect;
