import moment from 'moment';
import { sites } from './whitelabel';

export const formatName = (first_name, last_name) => {
  return capitalizeFirst(first_name) + " " + capitalizeFirst(last_name);
};

export const capitalizeFirst = (word) => {
  if (!word) {
    return "";
  }
  return word[0].toUpperCase() + word.slice(1);
};

export const capitalizeWords = (words) =>
  words.split(" ").map(capitalizeFirst).join(" ");

export const formatDate = (datestring) => {
  const date = new Date(datestring);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const formatEuroDate = (datestring) => {
  return new Date(datestring).toLocaleDateString("en-GB").replaceAll("/", "-");
};

export const getDob = (dob) => {
  var a = moment();
  var b = moment(dob, 'MM-DD-YYYY');
  var age = moment.duration(a.diff(b));
  var years = age.years();
  return years;
};

export const getClient = () => {
  const hostname = window.location.hostname;
  const currentSite = sites.find(site => hostname.indexOf(site.url) > -1);
  return currentSite;
};

export const getMessageTime = () => {
  return moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
}

export const isEmailValid = (input) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(input).toLowerCase());
};

export const sortLastNames = (obj1, obj2) => {
  const obj1LastName = obj1.last_name ? obj1.last_name.toLowerCase() : '';
  const obj2LastName = obj2.last_name ? obj2.last_name.toLowerCase() : '';

  if ( obj1LastName < obj2LastName ){
    return -1;
  }
  if ( obj1LastName > obj2LastName ){
    return 1;
  }
  return 0;
};