import styles from '../../index.module.css';
const {section, sectionTitle, divider} = styles;

const ICD = ({children}) => {
  return (
    <section className={section} style={{position: 'relative'}}>
        <h3 className={sectionTitle}>ICD Codes</h3>
        {children}
        <div className={divider}></div>
    </section>
  )
}

export default ICD